// extracted by mini-css-extract-plugin
export var address = "Home-module--address---+Fqb";
export var business = "Home-module--business--A5LID";
export var ceo = "Home-module--ceo--X-E1C";
export var contact = "Home-module--contact--inbTe";
export var container = "Home-module--container--LUC7D";
export var greenSection = "Home-module--greenSection--UGSnK";
export var logo = "Home-module--logo--d4tlw";
export var nameInfo = "Home-module--nameInfo--Yeyhm";
export var nameSection = "Home-module--nameSection--xfVlq";
export var peopleTargetImg = "Home-module--peopleTargetImg--IX2uk";
export var photoSection = "Home-module--photoSection--7VaI2";