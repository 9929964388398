import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import Logo from "../../static/huntman_logo.svg"
import "../styles/global.css"
import * as styles from "../styles/Home.module.css"

const IndexPage = () => (
  <div className={styles.container}>
    <Seo />
    <div className={styles.photoSection}>
      <StaticImage
        alt="Night scape"
        src="../images/background.png"
        // width={1500}
        formats={["auto", "webp", "avif"]}
        placeholder="blurred"
        layout="constrained"
        // objectFit="contain"
        objectPosition="left center" // important
        quality={100}
        style={{
          height: `100%`,
        }}
      />
    </div>
    <div className={styles.nameSection}>
      <div className={styles.greenSection}>
        <Logo className={styles.logo} />
        <div className={styles.nameInfo}>
          <p className={styles.ceo}>heejin seo</p>
          <p className={styles.business}>head consultant</p>
          <p className={styles.contact}>M. 82.10.7182.5446</p>
          <p className={styles.contact}>E. seo@huntman.co.kr</p>
          <div className={styles.address}>
            <p>#110 (1F), Sunmyung Square,</p>
            <p>111, Seongsuil-ro, Seongdong-gu,</p>
            <p>Seoul, Republic of Korea</p>
          </div>
        </div>
      </div>
      <div className={styles.peopleTargetImg}>
        <StaticImage
          alt="Target people"
          src="../images/people.svg"
          formats={["auto", "webp", "avif"]}
          placeholder="blurred"
          // objectFit="fill"
          style={{
            width: `100%`,
            height: `100%`,
          }}
        />
      </div>
    </div>
  </div>
)

export default IndexPage
